import { createSlice } from '@reduxjs/toolkit';

const lastVisited = localStorage.getItem("lastVisited") ?? "Oculus DV";

const gulerMakSlice = createSlice({
    name: "gulerMak",
    initialState: {
      activeFeature: lastVisited,
      internalActiveFea: "",
      openPrognosis: false,
      infinityCoreData: null,
      showListView: false,
      searchInAllFolders: null,
      foldersUpdatedDate: null,
      selectedCategoryName: null
    }, // Predictions // Oculus DV // Core // Pattern Recognition // Diagnostics // Scenario Modeling
    reducers: {
      changeFeature(state, action) {
        state.activeFeature = action.payload;
      },
      changeInternalFeature(state, action) {
        state.internalActiveFea = action.payload;
      },
      changeFeaturetoPrognosis(state, action) {
        state.activeFeature = action.payload;
        state.openPrognosis = true;
      },
      updateInfinityCoreData(state, action) {
        state.infinityCoreData = action.payload;
      },
      activeListView(state, action) {
        state.showListView = action.payload;
      },
      searchForFolders(state, action){
        state.searchInAllFolders = action.payload
      },
      updateFoldersUpdateDate(state, action){
        state.foldersUpdatedDate = action.payload
      },
      updateCategoryName(state, action) {
        state.selectedCategoryName = action.payload
      }
    },
});

export default gulerMakSlice;