// utility function that returns the projectIdentifier from localStorage

const getProjectIdentifier = () => {
    // if we have projectIdentifier in local storage, use it
    if (localStorage.getItem('projectIdentifier') !== null) {
        if (localStorage.getItem('projectIdentifier') !== "undefined") {
            return localStorage.getItem('projectIdentifier');
        }
    }


    return "895be50e-17e8-47c8-8367-f13d24ee87dd";
}

export default getProjectIdentifier