import React, { useState, useEffect, useRef } from 'react';
import { FiMaximize2, FiMinimize2, FiThumbsDown } from "react-icons/fi";
import { BiDislike } from "react-icons/bi";
import { FaXmark } from "react-icons/fa6";
import { PiPaperPlaneRightFill } from "react-icons/pi";
import { IoMicOutline, IoAttachOutline } from "react-icons/io5";

import './chatbot.css';

function VirtualAssistant({ 
    chatVisible,
    messages,
    messageInput,
    setMessageInput,
    handleInputChange,
    handleSendMessage ,
    handleAutoCommandClick,
    togglechat,
    setMiximize,
    miximize,
    handleFileChange
  }, ref) {

    const [speechSupported, setSpeechSupported] = useState(true);
    const [listening, setListening] = useState(false);
    const recognitionRef = useRef(null);
    const fileInputRef = useRef(null);
    
    useEffect(() => {
      try {
        // console.log("chatVisible", chatVisible)
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (SpeechRecognition) {
          const recognition = new SpeechRecognition();
          recognition.continuous = true;
          recognition.interimResults = true;
  
          recognition.onresult = (event) => {
            const transcript = Array.from(event.results)
              .map(result => result[0])
              .map(result => result.transcript)
              .join('');
            setMessageInput(transcript);
          };
  
          recognitionRef.current = recognition;
        } else {
          console.warn('Speech Recognition API not supported in this browser.');
          setSpeechSupported(false);
        }
      } catch (error) {
        console.error('Error initializing SpeechRecognition:', error);
        setSpeechSupported(false)
      }

      // console.log("chatVisible", chatVisible)
    }, [chatVisible]);

    const startListening = () => {
      if (recognitionRef.current) {
        recognitionRef.current.start();
        setListening(true);
      }
    };

    const stopListening = () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
        setListening(false);
      }
    };

    const dislikeActivationHandler = (e) => {
      e.target.classList.add('active');
    }

    // const handleFileChange = (event) => {
    //   const file = event.target.files[0];
    //   console.log('File selected:', file);
    //   // Handle file here (e.g., upload or display file)
    // };
  
    const triggerFileSelect = () => {
      fileInputRef.current.click();
    };

    
    return (
      <div className={`flex flex-col items-start ${chatVisible ? 'active' : ''}`}>
        
        {chatVisible && (
          
          <div className="va-chat shadow-lg">
              {/* <div className="flex flex-col items-start mt-2 p-2">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/AppScreen/logo.svg`}
                    className="w-14 h-14"
                    alt="robot_icon"
                  />
                </div> */}
                <div className="chat-head">
                  <img style={{width: "30px", marginLeft:"10px"}} src={`${process.env.PUBLIC_URL}/images/icons/mv-anim-icon.gif`} alt="animation" />
                  <div className='inline-flex gap-x-3'>
                    <button className="maxmin" onClick={()=>setMiximize(!miximize)}>
                      {
                        miximize ?
                        <FiMinimize2 /> :
                        <FiMaximize2 />
                      }
                    </button>
                    <button className="close" onClick={togglechat}>
                      <FaXmark />
                    </button>
                  </div>
                </div>
            <div className="va-messages" ref={ref}>
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`va-message ${message.type === 'assistant' ? 'assistant' : 'user'}`}
                >                
                  {
                    message.type === 'assistant' ?
                    <span className="avatar">
                      <img src={`${process.env.PUBLIC_URL}/favicon.ico`} alt="avatar" />
                    </span>
                    :
                    null
                  }
                  <div dangerouslySetInnerHTML={{ __html: message.text }}></div>
                  {
                    message.type === 'assistant' ?
                    <button className="dislikeResponse" onClick={dislikeActivationHandler}><BiDislike /></button>
                    : null
                  }
                  {
                    message.type === 'assistant' ? null
                    :
                    <span className="avatar">
                      <img src={`${process.env.PUBLIC_URL}/images/avatarmain.jpg`} alt="avatar" />
                    </span>
                  }
                </div>
              ))}
            </div>

            <div className="typing_area">
              <div className="textareaWrp">
                <button onClick={triggerFileSelect}><IoAttachOutline /></button>
                {
                  speechSupported && (
                    <button className={`ml-1 ${listening ? "active" : ""}`} onClick={listening ? stopListening : startListening}><IoMicOutline /></button>
                  )
                }
                <input type="file" ref={fileInputRef} style={{ display: 'none' }}  onChange={(e) => handleFileChange(e.target.files[0])} />
                <textarea
                  type="text"
                  id="userMsg"
                  value={messageInput}
                  onChange={handleInputChange}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleSendMessage();
                    }
                  }}
                  placeholder="Type your queries here."
                  className="text-start font-roboto font-regular font-roboto text-black tracking-wide"
                />
              </div>

              <button className="sendMsg my-auto" onClick={handleSendMessage} >
                <PiPaperPlaneRightFill />
              </button>
            </div>
          </div>
        )}
      </div>
    );
}

export default React.forwardRef(VirtualAssistant);